<template>
  <div>
    <MasterHeader />
    <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
    <div v-if="!isLoaded" class="loader">Loading...</div>
    <div class="container container-wide" v-if="isLoaded">
      <div class="row">
        <div class="widget-splash splash-line"></div>
        <div class="col-12">
          <div class="widget">
            <div class="widget-splash splash-line"></div>
            <div class="widget-header">
              <h4>
                <template v-if="isZealandFlow">Eventliste</template>
                <template v-else>Events list</template>
              </h4>
            </div>
            <div class="widget-body widget-suggestion">
              <p class="event_headers">
                <template v-if="isZealandFlow">Aktuelle events</template>
                <template v-else>Ongoing event</template>
              </p>
              <div class="" v-if="event.events.ongoingevent.length === 0">
                <p class="empty__events">
                  <template v-if="isZealandFlow"
                    >Ingen igangværende events</template
                  >
                  <template v-else>No Ongoing Events</template>
                </p>
              </div>
              <router-link
                v-else
                v-for="event in event.events.ongoingevent"
                :key="event.event_id"
                :to="'/events/' + event.event_name.split(' ').join('-')"
                ><img
                  class="event-image"
                  :src="eventImage(event.event_logo)"
                  alt=""
                  srcset=""
              /></router-link>
              <div class="separator"></div>
              <p class="event_headers">
                <template v-if="isZealandFlow">Fremtidige events</template>
                <template v-else>Future event</template>
              </p>
              <router-link
                v-for="event in event.events.futureevent"
                :key="event.event_id"
                :to="'/events/' + event.event_name.split(' ').join('-')"
                ><img
                  class="event-image"
                  :src="eventImage(event.event_logo)"
                  alt=""
                  srcset=""
              /></router-link>
              <div class="separator"></div>
              <p class="event_headers">
                <template v-if="isZealandFlow">Tidligere events</template>
                <template v-else>Past event</template>
              </p>
              <router-link
                v-for="event in event.events.pastevent"
                :key="event.event_id"
                :to="'/events/' + event.event_name.split(' ').join('-')"
                ><img
                  class="event-image"
                  :src="eventImage(event.event_logo)"
                  alt=""
                  srcset=""
              /></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MasterHeader from "@/components/MasterHeader.vue";
import { mapState } from "vuex";
import SignUpModal from "../../components/signUpModal.vue";
import { base64ToString } from "../../utils/utils";

let userRole = base64ToString(localStorage.getItem("i_am"));
let userId = base64ToString(localStorage.getItem("uid"));

export default {
  components: {
    MasterHeader,
    //SignUpModal,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  created() {
    // const userType = localStorage.getItem('i_am')
    // const userVerified = localStorage.getItem('u-verified')
    let userType = base64ToString(localStorage.getItem("i_am"));
    let userVerified = localStorage.getItem("u-verified");
    if (userVerified == 0 && userType == "student") {
      this.$router.push("/user/student-welcome");
    }
    if (userVerified == 0 && userType == "professional") {
      this.$router.push("/user/welcome");
    }
    if (userRole === "student" && !this.$store.getters.studentData) {
      this.$store.dispatch("getStudentData", userId);
    } else if (
      userRole === "professional" &&
      !this.$store.getters.professionalData
    ) {
      this.$store.dispatch("getProfessionalData", userId);
    }
  },
  mounted() {
    if (!this.event.events) {
      this.$store.dispatch("getEvents").then(() => (this.isLoaded = true));
    } else {
      this.isLoaded = true;
    }
  },
  computed: {
    ...mapState(["user", "event"]),
    hasNewSignupFlow() {
      const vm = this;
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false;
      const isStudent = vm.$store.getters.role === "Student";
      if (!isValid && isStudent) {
        localStorage.setItem("notSignedUp", 1);
      }
      if (isValid) {
        localStorage.removeItem("notSignedUp");
      }
      return true;
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  },
  methods: {
    eventImage(image) {
      if (!image) return false;
      if (image.includes("http")) {
        return image;
      } else {
        return `${process.env.VUE_APP_MEDIA_BASE_URL}${image}`;
      }
    },
  },
};
</script>
<style scoped>
.thesis-body {
  padding: 2rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}
.text-bold {
  font-weight: 700;
}
.thesis-button {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
  background: transparent;
}
table tr th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid #dee2e6;
}
table tr th:not(:first-child) {
  background-color: #fcfcfc;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.event-image {
  max-width: 500px;
  max-height: 500px;
  display: block;
  height: auto;
  margin: 20px 0;
  padding: 5px;
  border-radius: 5px;
  background: #dee2e6;
}
.empty__events {
  /* border-bottom: 5px solid #da518a;
  padding: 2% 0; */
}
.separator {
  border-bottom: 5px solid #da518a;
  padding: 2% 0;
}
.event_headers {
  font-weight: 500;
  margin-top: 15px;
}

@media (max-width: 499px) {
  .event-image {
    width: 100%;
  }
}
</style>
